import Header from '../Header'
import Footer from '../Footer'
import customer2 from '../../assets/images/customer2.jpg';
import customer2pdf from '../../assets/workshops/customer2.pdf';
import supervision from '../../assets/images/supervision.jpg';
import supervisionpdf from '../../assets/workshops/supervision.pdf';
import customer from '../../assets/images/customers.jpg';
import customerpdf from '../../assets/workshops/customers.pdf';
import worklife from '../../assets/images/work-life.jpg';
import worklifepdf from '../../assets/workshops/work-life.pdf';
import employeeperformance from '../../assets/images/employee-performance.jpg';
import employeeperformancepdf from '../../assets/workshops/employee-performance.pdf';
import interviewskillsjpg from '../../assets/images/interviewskills.jpg';
import interviewskillspdf from '../../assets/workshops/interviewskills.pdf';



import './publicworkshops.sass'

const PublicWorkshops = () => {

    return (

        <main className='publicworkshops'>

            <Header />


            <div className='container'>
                <h2 className='title'>Public Workshops</h2>

                <section className='workshop workshop__customer2'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>2.0 Working Successfully With Customers</h3>
                                                {/* <small className='workshop__unavaliable'> - No Classes Avaliable - </small> */}
                                                <p className='workshop__desc'>• Assertive communication techniques that will help to deescalate customers who are upset, angry or have emotionally lost it.
                                                • Listening skills to help you listen as a learner and not as a judge.
                                                • Self-management techniques to "stay cool under fire".
                                                • Problem-solving strategies to help you to stay focused on your objectives, and act proactively rather than reactively ("knee-jerk").
                                                • Options you have and the techniques to use when deal with and resolve conflict.
                                                • How to "Be no Ego." You do not have to attend every argument you have been invited to.
                                                • Internal customer service and being a team player working with peers and colleagues.
                                                • Empathic listening skills, and how to use empathy to connect with others, build rapport and work towards solutions.
                                                • Coping with negative people.
                                                • Techniques that help you "take pause' before acting or responding to someone.
                                                • Strategies to work with the "stress and strain" that comes from working with (and for) people who help to make the day a difficult one.
                                                • Communicate assertively, non-defensively and with self-confidence.
                                                • What you can to help build a collaborative workplace community.
                                                • Your role in helping to build a positve public image for your organization and for yourself
                                                </p> 
                                            <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>  
                                            <ul className='list'>
                                            
                                              
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/q6qkshu/lp/def46cf2-5bd7-4452-a93f-30170d08d2b4' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>February 11, 2025</h4>
                                                        <small>8:30 AM to 12:00 PM PT</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/npbydm9/lp/27dd3fd7-04ea-4c2a-ace2-1134afcabaab' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>February 13, 2025</h4>
                                                        <small>1:00 PM to 4:30 PM PT</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/u6gruwd/lp/b37d0827-c83a-4fcc-899a-1e627bea9a9b' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>February 19, 2025</h4>
                                                        <small>8:30 AM to 12:00 PM PT</small>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/s95z5hc/lp/1ffee877-85c5-4899-b716-14f170277051' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>February 21, 2025</h4>
                                                        <small>8:30 AM to 12:00 PM PT</small>
                                                    </a>
                                                </li>
                                            </ul>
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={customer2pdf} alt="Dealing Successfully With Customers 2.0 Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={customer2} alt="customer2 workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>

                <div className='grid grid--col2 grid--centered grid--space'>
                           
                           <div className='grid__col'>

                                    <section className='workshop workshop__supervision'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>Preparing for Supervision</h3>
                                                <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                                {/* <p className='workshop__desc'>Preparing for Supervision is a workshop for Public Sector employees who: * Want to promote and move up. * Are new to the job of supervision. * Have been assigned the role of lead worker. * Are thinking about moving up and taking on supervisory responsibilities. * Are experienced supervisors or managers who want to review the basics.</p> 
                                            <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>   */}
                                            {/* <ul className='list'>
                                            
                                              
                                                <li>
                                                    <a href='https://lp.constantcontactpages.com/ev/reg/q625j9t/lp/47f397ce-2a4f-400c-935c-7465cab555c6' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                        <h4 className='workshop__date'>November 21, 2024</h4>
                                                        <small>9:00 AM to 4:00 PM PT</small>
                                                    </a>
                                                </li>
                                            </ul> */}
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={supervisionpdf} alt="Preparing for Supervision Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={supervision} alt="supervision workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                        </div>
                        <div className='grid__col'>
                            <section className='workshop workshop__worklife'>
                                <div className='layout'>
                                    <div className='layout__col layout__col--7'>
                                        <h3 className='workshop__title'>Managing Your Time, Priorities, Work & Life Balance</h3>
                                        <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                        {/* <p className='workshop__desc'>Managing Your Time is a workshop for Public. Sectors employees who * Want to use their time effectively. • Set priorities to achieve goals & meet multiple job demands. • Create ways tp build life balance outside of work. • Reduce external barriers in managing time and interruptions. • Reduce negative stress through work & life balance.</p>
                                        <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                        <small> - Click a Date to Register - </small>
                                        <ul className='list'>
                                            <li>
                                                <a href='https://lp.constantcontactpages.com/ev/reg/hg954yd/lp/7dbd3ba3-c6ce-4fb9-81d3-27bd4c962cca' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                    <h4 className='workshop__date'>December 18, 2024	</h4>
                                                    <small>1:00 PM to 4:45 PM PT</small>
                                                </a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className='layout__col layout__col--3'>
                                        <a href={worklifepdf} alt="worklife Workshop Overview" target="_blank" rel="noreferrer">
                                            <img src={worklife} alt="worklife workshop pdf" className='workshop__img' />
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                <div className='grid grid--col2 grid--centered grid--space'>
                           
                                <div className='grid__col'>
                                
                                <section className='workshop workshop__interviewskills'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>Interview Skills Workshop</h3>
                                                <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                                {/* <p className='workshop__desc'>How to Deliver the Winning Interview is a workshop for Public Sector employees who want to:
                                                * Prepare thoroughly for the job interview process.
                                                * Learn techniques for presenting job qualifications in a focused and clear way.
                                                * Learn some of the don'ts and dos when responding to interview questions.
                                                * Build a career credibility profile.
                                                • Learn how to deal with difficult and unexpected questions during the interview process.
                                                • Deal with those job interview "butterflies" and get those "butterflies" to fly in formation.</p>
                                                <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                                <small> - Click a Date to Register - </small> */}
                                                {/* <ul className='list'>
                                                
                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/wd72qh9/lp/ef6557b2-8ca3-4632-b30e-cbcb3a9420eb'  target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>July 31, 2024</h4>
                                                            <small>1:00 PM to 4:30 PM PT</small>
                                                        </a>
                                                    </li> 
                                                </ul> */}
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={interviewskillspdf} alt="Interview Skills Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={interviewskillsjpg} alt="Improving Employee Performance workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>

                        </div>
                        <div className='grid__col'>

                        <section className='workshop workshop__customer'>
                                        <div className='layout'>
                                            <div className='layout__col layout__col--7'>
                                                <h3 className='workshop__title'>Dealing Successfully with Customers</h3>
                                                <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                                {/* <p className='workshop__desc'>This workshop designed for Public Sector Employees who: • Have direct contact with the public AND internal customers. • Must deal with angry, upset or highly emotional customers. • Want to learn customer service skills to build good will and a positive public image. • Want to understand their customer service role in a government environment.</p> 
                                                <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                                <small> - Click a Date to Register - </small>
                                                <ul className='list'>
                                                    <li>
                                                        <a href='https://lp.constantcontactpages.com/ev/reg/ttx7a39/lp/0401e804-b011-466b-84a8-73979f8649b5' target="_blank" rel="noreferrer" className='workshop__btn'>
                                                            <h4 className='workshop__date'>October 18, 2024</h4>
                                                            <small>8:30 AM to 12:00 Noon PT</small>
                                                        </a>
                                                    </li>

                                                   
                                                
                                                </ul> */}
                                            </div>
                                            <div className='layout__col layout__col--3'>
                                                <a href={customerpdf} alt="Preparing for Supervision Workshop Overview" target="_blank" rel="noreferrer">
                                                    <img src={customer} alt="supervision workshop pdf" className='workshop__img' />
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                    
                            </div>
                    </div>
                        <div className='grid grid--col2 grid--centered grid--space'>
                            <div className='grid__col'>
                                <section className='workshop workshop__employeeperformance'>
                                <div className='layout'>
                                    <div className='layout__col layout__col--7'>
                                        <h3 className='workshop__title'>Improving Employee Performance</h3>
                                        <small className='workshop__unavaliable'> - No Classes Avaliable - </small>
                                        {/* <p className='workshop__desc'>Improving Employee Performance is a workshop for Public. Sectors supervisors/managers who: • Communicate, problem solve & improve employee performance. • Set clear, realistic & achievable performance expectations. • Coach & inspire employees to work wth you when performance is below standard. • Prepare for the annual performance appraisal.</p>
                                        <h3 className='workshop__title workshop__title--sub'>Workshop Dates</h3>
                                        <small> - Click a Date to Register - </small>
                                        <ul className='list'>
                                        
                                            <li>
                                                <a href='http://events.constantcontact.com/register/event?llr=z59hqxqab&oeidk=a07ek7tsdiw3258dbc1'  target="_blank" rel="noreferrer" className='workshop__btn'>
                                                    <h4 className='workshop__date'>March 15, 2024</h4>
                                                    <small>8:15 AM to 12:00 PM PT</small>
                                                </a>
                                            </li> 
                                        </ul> */}
                                    </div>
                                    <div className='layout__col layout__col--3'>
                                        <a href={employeeperformancepdf} alt="Improving Employee Performance Workshop Overview" target="_blank" rel="noreferrer">
                                            <img src={employeeperformance} alt="Improving Employee Performance workshop pdf" className='workshop__img' />
                                        </a>
                                    </div>
                                </div>
                            </section>

                            </div>
                            <div className='grid__col'>            
                                
                           </div>
                        </div>
                        {/* <div className='grid grid--col2 grid--centered grid--space'>
                            <div className='grid__col'>
                           
                                 
                            </div>
                            <div className='grid__col'>
                            </div>
                        </div>
                 */}

            </div>

            <Footer />
        </main>

    )
}

export default PublicWorkshops